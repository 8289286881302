<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      currenTotal: 0,
      day: moment(new Date(Date.now())).format("YYYY-MM-DD"),
      payid: [],
      final_total: [],
      billing_invoices: [],
      customerData: [],
      page: 1,
      total: 0,
      status: 1,
      limit: 20,
      pay_amount: 0,
      total_amount: 0,
      tot_pages: 0,
      searchBillingInvoices: null,
      BillingInvoicesSearchMode: false,
      resultStatus: null,
      searchModel: "",
      billing_invoices_id: [],
      x: [],
      customerInfo: [],
      search: "",
      selectedCustomers: null,
      bill: [],
      allSelected: false,
      searchInput: null,
      customer_id: null,
      branch_id: null,
      customer_bill_id: null,

      // appprices:[]
    };
  },
  computed: {
    filteredCountries() {
      return this.customerInfo
        .filter((customerInfo) => {
          console.log(this.customerInfo);
          if (customerInfo.name.includes(this.search)) {
            console.log("mohamed test");
            console.log(customerInfo.name);
            return customerInfo.name;
          } else if (customerInfo.account_no == this.search) {
            return customerInfo.name;
          } else if (customerInfo.meter_no == this.search) {
            return customerInfo.name;
          }
        })
        .map((customerInfo) => {
          return (
            customerInfo.id +
            "-" +
            "(" +
            customerInfo.meter_no +
            ")" +
            "-" +
            customerInfo.name
          );
        });
    },
    generate() {
      return this.final_total
        .map((c) => {
          console.log("total", c.customer.tariff.fees);
          return c.customer.tariff.fees;
        })
        .reduce((a, b) => {
          this.total = parseInt(a) + parseInt(b);
          return this.total;
        }, 0);
    },
  },
  methods: {
    payCustomer(app) {
      console.log(app);
      this.customer_bill_id = app[0].id;
      this.http
        .post("billing-invoices/total-billings", {
          branch_id: this.branch_id,
          customer_id: app[0].id,
        })
        .then((resp) => {
          this.total_amount = resp.data;
        });
      console.log(app);
    },
    pay_bill() {
      const user = JSON.parse(localStorage.currentUser);
      if (!user) return;
      this.http
        .post("billing-invoices/pay-billings", {
          amount: this.pay_amount,
          customer_id: this.customer_bill_id,
          branch_id: this.branch_id,
          user_id: user?.id,
        })
        .then((resp) => {
          console.log("done");
          this.pay_amount = 0;
          this.total_amount = resp.data[0].total;
          if (this.total_amount == 0) {
            this.final_total = [];
            this.searchCustomer(this.searchModel);
          }
        });
    },
    selectAll() {
      this.allSelected = !this.allSelected;
      this.currenTotal = 0;
      this.bill = [];
      if (this.allSelected) {
        for (let i = 0; i < this.final_total.length; i++) {
          this.bill.push(this.final_total[i].id);
        }
      } else {
        this.bill = [];
      }
    },
    select(app, event) {
      console.log(event.target.checked);
      // this.allSelected = false;
      console.log(app);
      if (event.target.checked && this.allSelected == false) {
        this.currenTotal += parseFloat(app?.customer?.tariff?.fees);
      } else {
        this.currenTotal -= parseFloat(app?.customer?.tariff?.fees);
      }

      // if (event.target.checked && this.allSelected) {
      //   this.total += parseFloat(app?.customer?.tariff?.fees);
      // } else {
      //   this.total -= parseFloat(app?.customer?.tariff?.fees);
      // }
    },
    selectedCustomer() {
      this.http
        .post("customers/search", {
          search: this.selectedCustomers,
          col: "name",
        })
        .then((res) => {
          this.customerInfo = res.data;

          // for (let index = 0; index < res.data.length; index++) {
          //   this.customerInfo.push(res.data[index].name)
          // }
        });
    },

    addBillingInvoices() {
      var data = {
        title: "popups.addUsers",
        inputs: [
          {
            model: "id",
            type: "text",
            label: "id",
          },

          {
            model: "customer_id",
            type: "text",
            label: "customer_id",
          },

          {
            model: "status",
            type: "text",
            label: "status",
          },

          {
            model: "created_type",
            type: "text",
            label: "created_type",
          },

          {
            model: "created",
            type: "text",
            label: "created",
          },

          {
            model: "updated",
            type: "text",
            label: "updated",
          },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("billing_invoices", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    searchCustomer() {
      console.log("###", this.selectedCustomers.split("-")[1]);
      var searchInput = this.selectedCustomers.split("(")[1];
      this.searchInput = searchInput.split(")")[0];
      console.log(this.searchInput);
      this.BillingInvoicesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("billing-invoices/search", {
          search: this.searchInput,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          console.log("00000000", res.final_total);
          this.customerData = res.data;
          this.customer_id = res.data.id;
          this.final_total = res.final_total[0];

          // console.log(
          //   "***XX",
          //   this.customerData
          //     .map((tariff) => {
          //       return tariff.tariff.fees;
          //     })
          //     .reduce((a, b) => {
          //       return parseInt(a) + parseInt(b);
          //     }, 0)
          // );
          this.resultStatus = res.status;
          this.billing_invoices = res.data?.billing_invoices;
          for (let i = 0; i < this.customerData.length; i++) {
            this.billing_invoices = this.customerData[i]?.billing_invoices;
          }
          console.log({ billings: this.billing_invoices });
          // var tot = 0.0;
          // console.log("________", this.billing_invoices);

          // for (let j = 0; j < this.billing_invoices.length; j++) {
          //   // alert(this.billing_invoices[j]?.customer.tariff.fees)

          //   tot += parseFloat(this.billing_invoices[j]?.customer.tariff.fees);
          // }

          // this.billing_invoices = this.billing_invoices.sort((a, b) => {
          //   return a.status - b.status;
          // });
          // console.log(
          //   "--------------------------------",
          //   this.billing_invoices,
          //   tot
          // );
        });
    },
    pay() {
      console.log("@@@@2", this.day);
      console.log("app", this.x);
      this.billing_invoices?.map((a) => {
        this.payid.push({ id: a.id });
      }),
        console.log("billing", this.billing_invoices);
      this.billing_invoices?.customer?.name;
      this.billing_invoices_id = this.payid;
      this.http
        .post("billing-invoices/update", {
          status: this.status,
          billing_invoices: this.bill,
          code: this.billing_invoices[0]?.customer?.code,
          name: this.billing_invoices[0]?.customer?.name,
          branch_id: this.branch_id,
        })
        .then(() => {
          // this.get(this.page)
          console.log("&&&&&", this.billing_invoices);
          this.searchCustomer(this.searchModel);
        });

      console.log("@", this.billing_invoices_id);
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.BillingInvoicesSearchMode = false;
      this.customerData = [];
    },
    // getBillingInvoices(){
    // this.http.get("billing-invoices").then((res)=>{
    // this.billing_invoices = res.data
    // })
    // },
    deleteBillingInvoices(app) {
      var data = {
        title: "popups.deleteemp",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("billing-invoices", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      this.day = moment(new Date(Date.now())).format("YYYY-MM-DD");
      console.log(page);
      this.http
        .post("billing-invoices/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.customerData = res.data;
        });
    },
    // generate() {
    //
    //   this.final_total.map(c => {
    //     console.log("total", c.customer.tariff.fees)
    //     return c.customer.tariff.fees
    //   }).reduce((a, b) => {
    //     this.total = parseInt(a) + parseInt(b)
    //     return this.total

    //   }, 0);
    //   console.log("_)_)_", this.total)
    // }
  },

  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    // this.get(1)
    // this.selectedCustomer()
    //  this.getBillingInvoices()
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.billing.subItems.billing_invoices')"
    />
    <div
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="form-group row mb-3"
    >
      <label
        class="col-md-2.5 col-form-label"
        for="userName"
        style="font-size: 20px"
        >{{ $t("popups.customerinfor") }}</label
      >
    </div>
    <div
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="form-group row mb-3"
    >
      <div class="row">
        <div class="col-3">
          <AutoComplete
            @keyup="selectedCustomer()"
            v-model="selectedCustomers"
            :suggestions="filteredCountries"
            @complete="search = $event.query"
            style="color: #333 !important; border-radius: 10px !important"
          />
        </div>
        <div class="col">
          <div
            v-if="$i18n.locale == 'ar' ? 'ltr' : 'rtl'"
            class="col-md-4 w-50 mt-1"
          >
            <button
              @click="searchCustomer()"
              type="button"
              class="btn btn-primary mb-4"
            >
              {{ $t("popups.search") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        v-if="customerData.length"
      >
        <table
          class="table mt-5 table-centered table-nowrap table-striped table-hover align-middle"
        >
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("customers.id") }}</th>
              <th scope="col">{{ $t("customers.tariff") }}</th>
              <th scope="col">{{ $t("customers.neighborhood") }}</th>
              <th scope="col">{{ $t("customers.name") }}</th>
              <th scope="col">{{ $t("customers.account_no") }}</th>
              <th scope="col">{{ $t("customers.meter_no") }}</th>
              <th scope="col">{{ $t("customers.block") }}</th>
              <th scope="col">{{ $t("customers.created") }}</th>
              <th scope="col">{{ $t("customers.updated") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in customerData" :key="data" class="">
              <td>{{ index + 1 }}</td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? data?.tariff?.name
                    : data?.tariff?.name_en
                }}
              </td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? data?.neighborhood?.name
                    : data?.neighborhood?.name_en
                }}
              </td>
              <td>{{ data.name }}</td>
              <td>{{ data.account_no }}</td>
              <td>{{ data.meter_no }}</td>
              <td>{{ data.block }}</td>
              <td>{{ data.created.split("T")[0] }}</td>
              <td>{{ data.updated.split("T")[0] }}</td>
            </tr>
            <tr v-if="this.final_total.length == 0">
              <td colspan="9" class="text-center">
                <h2>{{ $t("popups.bills") }}</h2>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        :dir="$i18n.locale == 'ar' ? 'ltr' : 'rtl'"
        v-if="this.final_total.length"
      >
        <button
          @click="payCustomer(customerData)"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#bill"
          class="btn btn-primary mb-4"
        >
          {{ $t("customers.recive_billing") }}
        </button>
      </div>
      <!-- <div v-else>
            <h1></h1>
          </div> -->
    </div>
    <!-- end customer table -->

    <div v-if="customerData.length">
      <table
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        class="table table-centered table-nowrap table-striped table-hover align-middle"
      >
        <thead>
          <tr class="text-light" style="background-color: #2a3042 !important">
            <th scope="col">{{ $t("customers.id") }}</th>

            <th scope="col">{{ $t("customers.name") }}</th>
            <th scope="col">{{ $t("customers.tariff") }}</th>
            <th scope="col">{{ $t("customers.created") }}</th>
            <th scope="col">{{ $t("customers.updated") }}</th>
            <th scope="col">{{ $t("customers.status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(app, index) in billing_invoices" :key="app" class="">
            <td>{{ index + 1 }}</td>
            <td>{{ app?.customer?.name }}</td>
            <td>{{ parseFloat(app?.total_balance)?.toLocaleString() }}</td>
            <td>{{ app?.created?.split("T")[0] }}</td>
            <td>{{ app?.updated?.split("T")[0] }}</td>
            <td>
              <h6
                v-if="app?.paid_balance != app?.total_balance"
                class="text-danger"
              >
                {{ $t("popups.unPaid") }}
              </h6>
              <span
                v-if="app?.paid_balance == app?.total_balance"
                class="text-success"
                >{{ $t("popups.paid") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!--Start Pay Modal-->
    <div
      class="modal fade"
      id="payModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="pay_bill()">
            <div class="modal-header">
              <div class="d-flex justify-content-around">
                <h5 class="modal-title" id="exampleModalLabel">
                  <span>{{ $t("customers.total") }} : </span>
                  {{ total_amount }}
                </h5>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <input
                class="form-control mb-2 mx-1"
                v-model="pay_amount"
                :placeholder="$t('customers.amount')"
                type="number"
              />
              <div
                class="modal-footer"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              >
                <button type="submit" class="btn btn-primary">
                  {{ $t("customers.pay_customer") }}
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-danger"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Pay Modal-->

    <!-- Modal -->
    <div
      class="modal fade"
      id="bill"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <div class="row mt-4">
                <div class="col-12 col-lg-12">
                  <!-- <div class="row">
                                <div class="col-12">
                                    <div class="text-center text-150">
                                            <i class="fa fa-book fa-2x text-success-m2 mr-1"></i>
                                            <span class="text-default-d3">Bootdey.com</span>
                                        </div>
                                    </div>
                                </div> -->
                  <!-- .row -->
                  <div class="row">
                    <div class="col-sm-6">
                      <div>
                        <span class="text-sm text-grey-m2 align-middle"
                          >{{ $t("popups.to") }}:</span
                        >
                        <span
                          class="text-600 text-110 text-blue align-middle"
                          >{{ this.final_total[0]?.customer?.name }}</span
                        >
                      </div>
                      <div class="text-grey-m2">
                        <div class="my-1">
                          {{
                            $i18n.locale == "ar"
                              ? this.final_total[0]?.customer?.neighborhood
                                  ?.name
                              : this.final_total[0]?.customer?.neighborhood
                                  ?.name_en
                          }}
                        </div>
                        <!-- <div class="my-1">
                              State, customerInfo
                            </div>
                            <div class="my-1"><i class="fa fa-phone fa-flip-horizontal text-secondary"></i> <b
                                class="text-600">111-111-111</b></div> -->
                      </div>
                    </div>
                    <!-- /.col -->

                    <div
                      class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end"
                    >
                      <hr class="d-sm-none" />
                      <div class="text-grey-m2">
                        <div
                          class="mt-1 mb-2 text-secondary-m1 text-600 text-125"
                        >
                          {{ $t("popups.invoice") }}
                        </div>

                        <!-- <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span
                                class="text-600 text-90">ID:</span> #111-222</div> -->

                        <div class="my-2">
                          <i
                            class="fa fa-circle text-blue-m2 text-xs"
                            style="margin-inline-start: 2px"
                          ></i>
                          <span class="text-600 text-90">
                            {{ $t("popups.date") }} :
                          </span>
                          {{ this.day }}
                        </div>
                        <div
                          class="row my-2 align-items-center bgc-primary-l3 p-2"
                        >
                          <div class="col-12">
                            <span
                              class="text-150 tot text-success-d3 opacity-2"
                            >
                              {{ $t("popups.total_unpaid") }} :

                              <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'rtl'">
                                <!-- {{ generate.toLocaleString() }} -->
                                <span class="mx-1">
                                  {{
                                    parseFloat(total_amount).toLocaleString()
                                  }}</span
                                >
                              </div>
                            </span>
                          </div>
                        </div>

                        <!-- <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span
                                class="text-600 text-90">Status:</span> <span
                                class="badge badge-warning badge-pill px-25">Unpaid</span></div> -->
                      </div>
                    </div>
                    <!-- /.col -->
                  </div>

                  <div class="mt-4">
                    <table
                      v-if="final_total.length > 0"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      class="table mt-5 table-centered table-nowrap table-striped table-hover align-middle"
                    >
                      <thead>
                        <tr
                          class="text-light"
                          style="background-color: #2a3042 !important"
                        >
                          <th scope="col">{{ $t("customers.id") }}</th>
                          <th scope="col">{{ $t("customers.tariff") }}</th>
                          <th scope="col">
                            {{ $t("customers.neighborhood") }}
                          </th>
                          <th scope="col">{{ $t("customers.name") }}</th>
                          <th scope="col">{{ $t("customers.account_no") }}</th>
                          <th scope="col">{{ $t("customers.meter_no") }}</th>
                          <th scope="col">{{ $t("customers.block") }}</th>
                          <th scope="col">{{ $t("customers.fees") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(app, index) in final_total"
                          :key="app"
                          class=""
                        >
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{ index + 1 }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{
                              $i18n.locale == "ar"
                                ? customerData[0]?.tariff?.name
                                : customerData[0]?.tariff?.name
                                ? customerData[0]?.tariff?.name_en
                                : customerData[0]?.tariff?.name_en
                                ? customerData[0]?.tariff?.name_en
                                : customerData[0]?.tariff?.name
                            }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{
                              $i18n.locale == "ar"
                                ? app?.customer?.neighborhood?.name
                                : app?.customer?.neighborhood?.name_en
                            }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{ app?.customer?.name }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{ app?.customer?.account_no }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{ app?.customer?.meter_no }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{ app?.customer?.block }}
                          </td>
                          <td v-if="app?.total_balance != app?.paid_balance">
                            {{
                              parseFloat(app?.total_balance).toLocaleString()
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="final_total.length == 0">
                      <h4>{{ $t("popups.bills") }}</h4>
                    </div>
                    <div class="row border-b-2 brc-default-l2"></div>

                    <!-- or use a table instead -->
                    <!--
                              <div class="table-responsive">
                                  <table class="table table-striped table-borderless border-0 border-b-2 brc-default-l1">
                                      <thead class="bg-none bgc-default-tp1">
                                          <tr class="text-white">
                                              <th class="opacity-2">#</th>
                                              <th>Description</th>
                                              <th>Qty</th>
                                              <th>Unit Price</th>
                                              <th width="140">Amount</th>
                                          </tr>
                                      </thead>

                                      <tbody class="text-95 text-secondary-d3">
                                          <tr></tr>
                                          <tr>
                                              <td>1</td>
                                              <td>Domain registration</td>
                                              <td>2</td>
                                              <td class="text-95">$10</td>
                                              <td class="text-secondary-d2">$20</td>
                                          </tr> 
                                      </tbody>
                                  </table>
                              </div>
                              -->
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col"></div>
                    <div class="col d-flex justify-items-between">
                      <input
                        class="form-control mx-1"
                        v-model="pay_amount"
                        :placeholder="$t('customers.amount')"
                        type="number"
                      />
                      <button
                        :disabled="pay_amount == 0"
                        @click="pay_bill()"
                        type="button"
                        class="btn btn-primary float-end"
                      >
                        {{ $t("customers.recive") }}
                      </button>
                    </div>
                  </div>

                  <!-- <div>
                                          <span class="text-secondary-d1 text-105">Thank you for your business</span>
                                          <a href="#" class="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0">Pay Now</a>
                                      </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!-- Modal -->

    <!--end-->
    <!--   Apps  Pagination     -->
    <div v-if="tot_pages == 20">
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->

    <div class="page-content container">
      <!-- <div class="page-header text-blue-d2">
                            <h1 class="page-title text-secondary-d1">
                                Invoice
                                <small class="page-info">
                                    <i class="fa fa-angle-double-right text-80"></i>
                                    ID: #111-222
                                </small>
                            </h1>

                            <div class="page-tools">
                                <div class="action-buttons">
                                    <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="Print">
                                        <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                                        Print
                                    </a>
                                    <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="PDF">
                                        <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                                        Export
                                    </a>
                                </div>
                            </div>
                        </div> -->
    </div>
  </Layout>
</template>

<style scoped>
body {
  margin-top: 20px;
  color: #484b51;
}

.text-secondary-d1 {
  color: #728299 !important;
}

.page-header {
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  border-bottom: 1px dotted #e2e2e2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
}

.brc-default-l1 {
  border-color: #dce9f0 !important;
}

#bill {
  width: 100%;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-grey-m2 {
  color: #888a8d !important;
}

.text-success-m2 {
  color: #86bd68 !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-110 {
  font-size: 110% !important;
}

.text-blue {
  color: #478fcc !important;
}

.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}

.bgc-default-tp1 {
  background-color: rgba(121, 169, 197, 0.92) !important;
}

.bgc-default-l4,
.bgc-h-default-l4:hover {
  background-color: #f3f8fa !important;
}

.page-header .page-tools {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}

.w-2 {
  width: 1rem;
}

.text-120 {
  font-size: 120% !important;
}

.text-primary-m1 {
  color: #4087d4 !important;
}

.text-danger-m1 {
  color: #dd4949 !important;
}

.text-blue-m2 {
  color: #68a3d5 !important;
}

.text-150 {
  font-size: 150% !important;
}

.text-60 {
  font-size: 60% !important;
}

.text-grey-m1 {
  color: #7b7d81 !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.modal-content {
  width: 800px;
}

.c {
  position: relative;
  right: 900px;
  top: 5px;
}

.tot {
  display: flex;
  justify-content: flex-end;
}

.tot2 {
  display: flex;
  justify-content: flex-end;
  right: 10px;
}
</style>
